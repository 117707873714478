var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"open-hall"},[_c('header',[_c('h3',[_vm._v("欢迎进入"+_vm._s(_vm.tradeCenterNoName)+"公共资源交易中心开标大厅")])]),_c('section',[_c('div',{staticClass:"top"},[_c('p',[_c('span',[_vm._v("今日开标项目")]),_c('span',[_vm._v(_vm._s(_vm.todayNum))])]),_c('p',[_c('span',[_vm._v("正在开标项目")]),_c('span',[_vm._v(_vm._s(_vm.numIng))])])]),_c('div',{staticClass:"table"},[_c('table',{staticStyle:{"border-collapse":"collapse"}},[_c('tbody',[_vm._m(0),_vm._l((_vm.data),function(e,i){return _c('tr',{key:i,attrs:{"align":"center"}},[_c('td',{attrs:{"title":e.projectNo}},[_vm._v(_vm._s(e.projectNo))]),_c('td',{attrs:{"title":e.projectName}},[_vm._v(_vm._s(e.projectName))]),_c('td',{attrs:{"title":e.bidSection}},[_vm._v(_vm._s(e.bidSection))]),_c('td',{attrs:{"title":e.platform}},[_vm._v(_vm._s(e.platform))]),_c('td',{attrs:{"title":e.meetingStartDate}},[_vm._v(_vm._s(e.meetingStartDate))]),_c('td',{style:({
                color:
                  e.state === '正在进行'
                    ? '#0ADB76'
                    : e.state === '未开标'
                    ? '#FFBA00'
                    : '#CABEBE'
              }),attrs:{"title":e.state}},[_vm._v(" "+_vm._s(e.state)+" ")]),_c('td',[_c('span',{on:{"click":function($event){return _vm.fun_GetInto(e)}}},[_vm._v("进入")])])])})],2)])])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{attrs:{"align":"center"}},[_c('th',{attrs:{"width":"15%"}},[_vm._v("项目编号")]),_c('th',{attrs:{"width":"20%"}},[_vm._v("项目名称")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("标段")]),_c('th',{attrs:{"width":"15%"}},[_vm._v("交易平台")]),_c('th',{attrs:{"width":"15%"}},[_vm._v("开标时间")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("开标进度")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("操作")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('p',[_vm._v(" Copyright © 2014-2015 武汉招投评标系统 版权所有 | 技术服务：武汉墨仗信息科技股份有限公司 ")])])
}]

export { render, staticRenderFns }